.cluster {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #198754;
    color: white;
    padding: 12px;
    border-radius: 100%;
    font-size: 14px;
    font-weight: 600;
    border: solid 1px white;
}

.marker {
    transition: transform 0.3s ease;
    cursor: pointer;
}

.marker:hover {
    transform: scale(1.5);
}

.marker-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.marker-label {
    display: none;
    position: absolute;
    bottom: 45px;
    background: #198754;
    color: white;
    padding: 4px 6px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    pointer-events: none;
    border: solid 1px white;
    z-index: 9999;
}

.marker-container:hover .marker-label {
    display: block;
}

