:root {
  --color-fund: rgba(255, 255, 255, 0.6);
}

* {
  transition: all 0.1s;
  font-family: "Open Sans";
}

.MuiIconButton-label .MuiSvgIcon-root {
  color: #1b5e20 !important;
}

.filter-maps {
  position: fixed;
  top: 0;
  left: 200px;
  right: 0;
  z-index: 1000;
  background-color: #0c392e87;
  color: #f4f4f4;
}


.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #198754 !important;
  color: white !important;
  border-color: white !important;
  font-size: 14px;
  font-weight: 500;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: #198754 !important;
  border-color: #198754 !important;
  font-size: 14px;
  font-weight: 500;
}

.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-link:hover {
  background-color: #157347 !important;
  color: white !important;
  font-size: 14px;
  font-weight: 500;
}

.image-fixed {
  width: 800px;
  height: 480px;
  object-fit: cover;
  cursor: pointer;
}

.placeholder {
  width: 800px;
  height: 480px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  font-size: 16px;
  font-family: Arial, sans-serif;
}

.scrollable-container {
  height: 300px;
  overflow-x: auto;
  overflow-y: auto;
  background-color: black;
  color: white;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #444;
  border-radius: 5px;
}

.scrollable-text {
  margin: 0;
  max-width: 800px;
  white-space: pre-wrap;
  font-size: 14px;
  text-align: left;
}

.custom-table th {
  background-color: #343a40;
  color: white;
}

.custom-table td {
  background-color: #212529;
  color: #ced4da;
}

.italic-input {
  font-style: italic;
  font-size: 16pt;
}

.input-dark::placeholder {
  color: #ffffffa1;
  opacity: 1;
}

.input-dark {
  background-color: #111;
  color: #fff;
  border: 1px solid #fff;
}

.input-dark:focus {
  background-color: #111;
  color: #fff;
  border: 1px solid #fff;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}

.btn-dark {
  background-color: #333;
  color: #fff;
  border: 1px solid #fff;
}

.text-red {
  color: red;
}

.text-green {
  color: green;
}

.form-control:focus {
  border: none;
  border-color: #015431aa !important;
  box-shadow: 0 0 0 .15rem #198754cb !important;
}

.reload-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #1C4220;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#dropdown-multi-select {
  width: 100%;
}

.pagination .page-item.active .page-link {
  background-color: #198754;
  border-color: #198754;
  color: #f4f4f4;
}

.pagination .page-item:hover .page-link {
  background-color: #157347;
  border-color: #157347;
  color: #f4f4f4;
}


@media (max-width: 1279px) {
  /* Adicione aqui os estilos para telas menores que 1279px */

  .header {
    margin-top: 50px;
  }

  .filter-maps {
    left: 0 !important;
    top: 52px !important;
  }

}


@media (max-width: 1200px) {
  html {
    font-size: 90%;
  }
}

@media (max-width: 992px) {
  html {
    font-size: 85%;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 75%;
  }
}