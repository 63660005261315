.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  max-width: 500px;
  height: auto;
}

video {
  object-fit: cover;
  max-height: 400px;
  /* Ajuste a altura máxima para evitar overflow */
}

.min-width-button {
  min-width: 120px;
}